import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import { Intro } from "components";

const HowItWorksPage = ({ location, data }) => {
  return (
    <Layout
      location={location}
      pageTitle="How it works - Reprocess & Repurpose"
    >
      <Intro slides={data.allContentfulHowItWorksSlides.edges} />
    </Layout>
  );
};

export const query = graphql`
  query HowItWorksPageQuery {
    allContentfulHowItWorksSlides(sort: { fields: order }) {
      edges {
        node {
          id
          title
          background {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
          content {
            raw
          }
        }
      }
    }
  }
`;

export default HowItWorksPage;
